import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import auth from '../helpers/auth'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log(to, 'to')
  if ((!auth.getUser() && to.name === 'profile') || (!auth.getUser() && to.path === '/home/draft') || (!auth.getUser() && to.path === '/home/add-info-jabatan')) {
    window.location.href = `https://account.kemnaker.go.id/auth?response_type=code&client_id=${process.env.VUE_APP_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_LOGIN_URL}&scope=basic email`
    // window.location.href = 'https://account.kemnaker.go.id/auth?response_type=code&client_id=01723b89-180d-436d-abd5-dcab7869547e&redirect_uri=https://dev.otellogroup.id/&scope=basic email'
    // window.location.href = 'https://account.kemnaker.go.id/auth?response_type=code&client_id=01723b89-180d-436d-abd5-dcab7869547e&redirect_uri=http://localhost:8080/&scope=basic email'
  } else {
    next()
  }
})

export default router
