import router from '../router'
import cookies from 'vue-cookies'

export default {
  setLogin (user) {
    cookies.config('8h')
    cookies.set('user', user)
  },
  checkCookies () {
    const userApp = cookies.get('user')
    if (userApp == null) {
      router.push('/login')
    }
  },
  getUser () {
    return cookies.get('user')
  },
  removeCookies () {
    return cookies.keys().forEach(cookie => cookies.remove(cookie))
  },
  setSSOToken (token) {
    cookies.config('8h')
    cookies.set('sso_token', token)
  },
  setToken (token) {
    cookies.config('8h')
    cookies.set('token', token)
  },
  getSSOToken () { // Bearer eyadf
    return cookies.get('sso_token')
  },
  getToken () { // 3|Psdf234
    return cookies.get('token')
  }
}
