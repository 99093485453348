<template>
  <div class="">
    <nav class="navbar navbar-expand-lg navbar-dark" :class="{ customBackgroundNavbar: isTransparant, expanded: navExpand }">
      <div class="container-fluid">

        <a @click="toHome" class="navbar-brand">
          <img class="link-to-home" width="154px" src="../../assets/images/logo-white.png" alt="" />
        </a>

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          ref="navExpandable"
          @click="expandNav"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarNav">
          <div class="navbar-nav me-auto menu-navbar">
            <li class="nav-item">
              <a class="nav-link" aria-current="page" @click="toHome">Beranda</a>
            </li>
            <li class="nav-item dropdown align-items-center">

              <a
                class="btn nav-link dropdown-toggle test"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-offset="10,20"
              >Informasi Jabatan</a>

              <div class="dropdown-menu i-position-dropdown" aria-labelledby="navbarDropdown">
                <div class="i-position-type-dropdown">
                  <a class="dropdown-item">
                    <div class="i-position-type-icon">
                      <img
                        class="align-middle mb-1"
                        width="32"
                        height="32"
                        src="../../assets/images/ic-book.svg"
                        alt=""
                      />
                    </div>
                    <div class="i-position-type-info">
                        <div class="i-position-type-title">
                            Kamus Jabatan Nasional (KJN)
                        </div>
                        <p @click="toKjn" class="sub-menu-title">Kategori/Lapangan Usaha</p>
                        <p @click="toTka" class="sub-menu-title">Tenaga Kerja Asing</p>
                        <p @click="toDisabilitas" class="sub-menu-title">Disabilitas</p>
                        <p>Pekerja Migran Indonesia</p>
                    </div>
                  </a>

                  <a @click="toKbji" class="dropdown-item">
                    <div class="i-position-type-icon">
                      <img
                        class="align-middle mb-1"
                        width="32"
                        height="32"
                        src="../../assets/images/ic-diagram.svg"
                        alt=""
                      />
                    </div>
                    <div class="i-position-type-info">
                      <div class="i-position-type-title">
                        Klasifikasi Baku Jabatan Indonesia (KBJI) tahun 2014
                      </div>
                      <p>Golongan Pokok</p>
                    </div>
                  </a>
                </div>
              </div>
            </li>
            <li class="nav-item dropdown align-items-center">
              <a
                class="btn nav-link dropdown-toggle test"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-offset="10,20"
              >Lainnya</a>
              <div class="dropdown-menu i-position-dropdown" aria-labelledby="navbarDropdown">
                <div
                  class="i-position-type-dropdown"
                  style="min-width: max-content;"
                >
                  <a @click="toAbout" class="dropdown-item" style="margin-right: 40px;">
                    <div class="i-position-type-icon">
                      <img
                        class="align-middle mb-1"
                        width="32"
                        height="32"
                        src="../../assets/images/information.svg"
                        alt=""
                      />
                    </div>
                    <div class="i-position-type-info">
                      <div class="i-position-type-title">
                        Tentang e-Jabatan
                      </div>
                      <p>Tentang e-Jabatan</p>
                    </div>
                  </a>
                  <a class="dropdown-item" style="margin-right: 40px;">
                    <div class="i-position-type-icon">
                      <img
                        class="align-middle mb-1"
                        width="32"
                        height="32"
                        src="../../assets/images/manual.svg"
                        alt=""
                      />
                    </div>
                    <div class="i-position-type-info">
                      <div class="i-position-type-title">
                        Manual Book e-Jabatan
                      </div>
                      <p>Download User Manual</p>
                    </div>
                  </a>
                </div>
              </div>
            </li>
          </div>
          <div class="navbar-nav justify-content-end control-navbar align-items-center">
            <!-- <li class="nav-item bordered">
              <div class="dropdown">
                <a href="#" class="user-control-dropdown-toggler">
                  <div class="user-control-dropdown-toggler-wrapper">
                    <div class="user-control-dropdown-picture">
                      <img src="https://account.kemnaker.go.id/users/82890a36-57f4-4866-8805-a35dbe91854e/picture" alt="" />
                    </div>
                    <div class="user-control-dropdown-arrow"></div>
                  </div>
                </a>
                <div class="user-control-dropdown-menu user-control-dropdown-menu-right d-none">
                  <div class="user-control-dropdown-item-wrapper user-control-dropdown-menu-panel user-control-dropdown-menu-header">
                    <div class="user-control-dropdown-item">
                      <div class="user-control-dropdown-item-header">
                        <div class="user-control-dropdown-item-picture">
                          <img src="https://account.kemnaker.go.id/users/82890a36-57f4-4866-8805-a35dbe91854e/picture" alt="" />
                        </div>
                        <div class="user-control-dropdown-item-header-content">
                          <h5>John Doe</h5>
                          <p class="text-muted">johndoe@gmail.com</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="user-control-dropdown-item-wrapper user-control-dropdown-menu-divider"></div>
                  <div class="user-control-dropdown-item-wrapper user-control-dropdown-menu-body">
                    <a href="" class="user-control-dropdown-item user-control-dropdown-menu-panel text-decoration-none fw-semibold">Profil</a>
                    <a href="" class="user-control-dropdown-item user-control-dropdown-menu-panel text-decoration-none fw-semibold">Pengaturan</a>
                    <a href="" class="user-control-dropdown-item user-control-dropdown-menu-panel text-decoration-none fw-semibold">Keluar</a>
                  </div>
                </div>
              </div>
            </li> -->
            <li class="nav-item btn-group">

              <button
                type="button"
                class="btn btn-outline-light"
                aria-expanded="false"
                @click="toAddJabatan"
              >
                Beri Usulan Informasi Jabatan
              </button>

              <!-- <button
                type="button"
                class="btn btn-outline-light dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                id="dropdownJabatan"
              >
                Beri Usulan Informasi Jabatan
              </button> -->

              <!-- <ul
                aria-labelledby="dropdownJabatan"
                class="dropdown-menu dropdown-menu-end mt-2"
              >
                <li>
                  <button @click ="toAddJabatan" class="dropdown-item" type="button">
                    <span class="">
                      Beri Usulan Jabatan xxx
                    </span>
                  </button>
                </li>
                <li>
                  <button class="dropdown-item" type="button">
                    <span class="">
                      Beri Usulan Jabatan yyy
                    </span>
                  </button>
                </li>
              </ul> -->

            </li>
            <li class="nav-item">
              <a class="btn btn-light">
                <svg
                  _ngcontent-alw-c44=""
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <g _ngcontent-alw-c44="" fill="none" fill-rule="evenodd">
                    <path _ngcontent-alw-c44="" fill="#FFF" d="M-1116-77H324v4104h-1440z"></path>
                    <g _ngcontent-alw-c44="" fill="#002D58">
                      <rect _ngcontent-alw-c44="" width="4" height="4" rx="1"></rect>
                      <rect _ngcontent-alw-c44="" width="4" height="4" x="6" rx="1"></rect>
                      <rect _ngcontent-alw-c44="" width="4" height="4" x="12" rx="1"></rect>
                      <rect _ngcontent-alw-c44="" width="4" height="4" y="6" rx="1"></rect>
                      <rect _ngcontent-alw-c44="" width="4" height="4" x="6" y="6" rx="1"></rect>
                      <rect _ngcontent-alw-c44="" width="4" height="4" x="12" y="6" rx="1"></rect>
                      <rect _ngcontent-alw-c44="" width="4" height="4" y="12" rx="1"></rect>
                      <rect _ngcontent-alw-c44="" width="4" height="4" x="6" y="12" rx="1"></rect>
                      <rect _ngcontent-alw-c44="" width="4" height="4" x="12" y="12" rx="1"></rect>
                    </g>
                  </g>
                </svg>
                &nbsp; Layanan
              </a>
            </li>
            <li class="nav-item">
              <button
                v-if="!isUserSignedIn"
                class="btn btn-primary"
                @click="toLogin"
              >
                Login
              </button>
              <div v-else class="btn-group">
                <button type="button" class="btn btn-profile" data-bs-toggle="dropdown" aria-expanded="false">
                  {{ initials }}
                </button>
                <ul
                  class="dropdown-menu dropdown-menu-end mt-2"
                  style="width: 300px;"
                >
                  <li class="py-2 px-3">
                    <p class="my-1 fs-5 fw-bold text-truncate">{{ name }}</p>
                    <p class="my-1 text-truncate">{{ email }}</p>
                  </li>
                  <hr style="margin: 5px;">
                  <li>
                    <button @click="toProfile" class="dropdown-item" type="button">
                      <i class="bi bi-person-circle"></i>
                      <span class="submenu-font">
                        Profil
                      </span>
                    </button>
                  </li>
                  <li>
                    <button @click="toDraft" class="dropdown-item" type="button">
                      <i class="bi bi-pencil-square"></i>
                      <span class="submenu-font">
                        Draft
                      </span>
                    </button>
                  </li>
                  <li>
                    <button @click="toInbox" class="dropdown-item" type="button">
                      <i class="bi bi-envelope"></i>
                      <span class="submenu-font">
                        Inbox
                      </span>
                    </button>
                  </li>
                  <li>
                    <button @click="toSetting" class="dropdown-item" type="button">
                      <i class="bi bi-gear"></i>
                      <span class="submenu-font">
                        Pengaturan Akun
                      </span>
                    </button>
                  </li>
                  <li>
                    <button @click="logout" class="dropdown-item" type="button">
                      <i class="bi bi-box-arrow-right"></i>
                      <span class="submenu-font">
                        Keluar
                      </span>
                    </button>
                  </li>
                </ul>
              </div>
            </li>
          </div>
        </div>
      </div>
    </nav>
    <router-view />
  </div>
</template>

<script>
import auth from '../../helpers/auth.js'
export default {
  data () {
    return {
      navBgColor: 'transparent',
      navClass: 'navbar navbar-expand-lg navbar-dark',
      navExpand: false,
      isTransparant: false,
      isUserSignedIn: false
    }
  },
  computed: {
    currPath () {
      return this.$route.fullPath
    },
    initials () {
      const userName = auth.getUser().name
      const name = userName.split(' ')
      let initials = name[0].substring(0, 1).toUpperCase()
      if (name.length > 1) {
        initials += name[name.length - 1].substring(0, 1).toUpperCase()
      }
      return initials
    },
    name () {
      return auth.getUser().name
    },
    email () {
      return auth.getUser().email
    }
  },
  watch: {
    currPath (path) {
      console.log('path', path)
      if (auth.getUser()) {
        this.isUserSignedIn = true
      }
      if (path !== '/' && path !== '/home' && path !== '/home/tentang' && !path.includes('/home/detail') && !path.includes('/home/kjn_golongan_detail')) {
        // this.navClass += ' custom-background-navbar'
        this.isTransparant = true
      } else {
        console.log('replace')
        this.navClass = this.navClass.replace(' custom-background-navbar', '')
        this.isTransparant = false
      }
    }
  },
  mounted () {
    const path = this.$route.fullPath
    console.log('path', path)
    if (auth.getUser()) {
      this.isUserSignedIn = true
    }
    if (path !== '/' && path !== '/home' && path !== '/home/tentang' && !path.includes('/home/detail') && !path.includes('/home/kjn_golongan_detail')) {
      // this.navClass += ' custom-background-navbar'
      this.isTransparant = true
    }
    window.addEventListener("scroll", this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener("scroll", this.handleScroll)
  },
  methods: {
    toLogin () {
      window.location.href = `https://account.kemnaker.go.id/auth?response_type=code&client_id=${process.env.VUE_APP_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_LOGIN_URL}&scope=basic email`
      // window.location.href = 'https://account.kemnaker.go.id/auth?response_type=code&client_id=01723b89-180d-436d-abd5-dcab7869547e&redirect_uri=https://dev.otellogroup.id/&scope=basic email'
      // window.location.href = 'https://account.kemnaker.go.id/auth?response_type=code&client_id=01723b89-180d-436d-abd5-dcab7869547e&redirect_uri=http://localhost:8080/&scope=basic email'
      // window.open('https://account.kemnaker.go.id/auth?response_type=code&client_id=01723b89-180d-436d-abd5-dcab7869547e&redirect_uri=https://dev.otellogroup.id/&scope=basic email')
      // this.$router.replace('https://account.kemnaker.go.id/auth?response_type=code&client_id=01723b89-180d-436d-abd5-dcab7869547e&redirect_uri=https://dev.otellogroup.id/&scope=basic email')
      if (this.navExpand) this.closeExpandedNav()
    },
    toSetting () {
      window.location.href = 'https://account.kemnaker.go.id/settings/security'
    },
    logout () {
      window.location.href = `https://account.kemnaker.go.id/auth/logout?continue=${process.env.VUE_APP_LOGIN_URL}`
      // window.location.href = 'https://account.kemnaker.go.id/auth/logout?continue=https://dev.otellogroup.id'
      // window.location.href = 'https://account.kemnaker.go.id/auth/logout?continue=http://localhost:8080'
      auth.removeCookies()
      localStorage.removeItem('codeNaco')
    },
    toProfile () {
      this.$router.push('/profile')
      if (this.navExpand) this.closeExpandedNav()
    },
    toHome () {
      if (this.$route.fullPath === '/' || this.$route.path === '/home') return ''
      this.$router.push('/home')
      if (this.navExpand) this.closeExpandedNav()
    },
    toKjn () {
      if (this.$route.fullPath !== '/home/kjn') this.$router.push('/home/kjn')
      if (this.navExpand) this.closeExpandedNav()
    },
    toTka () {
      if (this.$route.fullPath !== '/home/tka') this.$router.push('/home/tka')
      if (this.navExpand) this.closeExpandedNav()
    },
    toDisabilitas () {
      if (this.$route.fullPath !== '/home/disabilitas') this.$router.push('/home/disabilitas')
      if (this.navExpand) this.closeExpandedNav()
    },
    // toDraft () {
    //   if (this.$route.fullPath !== '/home/draft') this.$router.push('/home/draft')
    // },
    toKbji () {
      console.log(this.$route)
      if (this.$route.fullPath !== '/home/kjn_golongan') this.$router.push('/home/kjn_golongan')
      if (this.navExpand) this.closeExpandedNav()
    },
    toAbout () {
      if (this.$route.fullpath !== '/home/tentang') this.$router.push('/home/tentang')
      if (this.navExpand) this.closeExpandedNav()
    },
    toDraft () {
      if (this.$route.fullpath !== '/home/draft') this.$router.push('/home/draft')
      if (this.navExpand) this.closeExpandedNav()
    },
    toInbox () {
      this.$router.push('/inbox')
      if (this.navExpand) this.closeExpandedNav()
    },
    closeExpandedNav () {
      this.$refs.navExpandable.click()
    },
    toAddJabatan () {
      this.$router.push('/home/add-info-jabatan')
    },
    expandNav () {
      const contain = this.navClass.includes('expanded')
      if (this.navExpand == false) {
        // this.navClass += ` expanded`
        this.navExpand = true
      } else {
          // this.navClass = this.navClass.replace(' expanded', '')
        // setTimeout(() => {
          // this.navClass = this.navClass.replace(' expanded', '')
        // }, 200)
        this.navExpand = false
      }
    },
    handleScroll (event) {
      console.log('currPath', this.currPath, this.currPath.includes('/home/kjn_golongan_detail'))
      if (this.currPath !== '/home/tentang' && !this.currPath.includes('/home/detail') && !this.currPath.includes('/home/kjn_golongan_detail')) return
      console.log('pass logic')
      const windowTop = window.top.scrollY
      console.log('windowTop', windowTop)
      if (windowTop > 50) {
          this.isTransparant = true
      } else {
        // this.navClass = this.navClass.replace(' custom-background-navbar', '')
        this.isTransparant = false
      }
    }
  }
}
</script>

<style>
  @import url('../../assets/styles/navbar.css');
  .custom-background-navbar {
    background-color: #15406A;
  }

  .customBackgroundNavbar {
    background-color: #15406A;
  }

  .page-item .active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #15406a;
    border-color: #15406a;
  }

  li.page-link.active {
    z-index: 3;
    color: #fff;
    background-color: #15406a;
  }

  li.page-link {
    position: relative;
    display: block;
    color: #15406a;
    background-color: #fff;
    border: 1px solid #dee2e6;
  }

  a {
    text-decoration: none !important;
  }

  .sub-menu-title:hover {
    text-decoration: underline;
  }
  .btn-profile {
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 50% !important;
  }
  .btn-profile:hover {
    background-color: #d3d4d5;
    color: black;
  }
  .btn-profile.show {
    background-color: white;
    color: black;
    border-color: white;
  }
  .submenu-font {
    font-weight: 500;
    margin-left: 5px;
    font-size: 18px;
  }
</style>
