import Navbar from '../pages/navbar/navbar.vue'

const SignIn = () => import('../pages/auth/signIn.vue')
const Hero = () => import('../pages/index.vue')
const Kjn = () => import('../pages/kamusJabatanNasional/kjn.vue')
const Tka = () => import('../pages/kamusJabatanNasional/tka.vue')
const Disabilitas = () => import('../pages/kamusJabatanNasional/disabilitas.vue')
const Kbji = () => import('../pages/kamusJabatanNasional/kbji.vue')
const KbjiDetail = () => import('../pages/kamusJabatanNasional/kbjiDetail.vue')
const QuickSearch = () => import('../pages/search/quickSearch.vue')
const AdvanceSearch = () => import('../pages/search/advance-search.vue')
const Detail = () => import('../pages/search/detail.vue')
const DetailTka = () => import('../pages/search/detailTka.vue')
const DetailDisabilitas = () => import('../pages/search/detailDisabilitas.vue')
const DraftSunting = () => import('../pages/kamusJabatanNasional/draftSunting.vue')
const Tentang = () => import('../pages/tentang.vue')
const PdfSheet = () => import('../pages/pdf/detailPdf2.vue')
const addInfoJabatan = () => import('../pages/informasiJabatan/addInfoJabatan.vue')
const Profile = () => import('../pages/profile/profile.vue')
const Inbox = () => import('../pages/inbox/inbox.vue')

const landingPage = {
  path: '/',
  component: Navbar,
  children: [
    {
      path: '/',
      name: 'Hero',
      components: { default: Hero }
    },
    {
      path: '/inbox',
      name: 'inbox',
      components: { default: Inbox }
    },
    {
      path: '/profile',
      name: 'profile',
      components: { default: Profile }
    }
  ]
}

const auth = {
  path: '/sign_in',
  name: 'Sign In',
  components: { default: SignIn }
}

const home = {
  path: '/home',
  component: Navbar,
  children: [
    {
      path: '/',
      name: 'Hero Home',
      components: { default: Hero }
    },
    {
      path: 'kjn',
      name: 'Kamus Jabatan Nasional',
      components: { default: Kjn }
    },
    {
      path: 'tka',
      name: 'Tenaga Kerja Asing',
      components: { default: Tka }
    },
    {
      path: 'disabilitas',
      name: 'Disabilitas',
      components: { default: Disabilitas }
    },
    {
      path: 'kjn_golongan',
      name: 'Klasifikasi Baku Jabatan Indonesia',
      components: { default: Kbji }
    },
    {
      path: 'kjn_golongan_detail',
      name: 'Klasifikasi Baku Jabatan Indonesia Detail',
      components: { default: KbjiDetail }
    },
    {
      path: 'quick-search',
      name: 'QuickSearch',
      components: { default: QuickSearch }
    },
    {
      path: 'advance-search',
      name: 'AdvanceSearch',
      components: { default: AdvanceSearch }
    },
    {
      path: 'detail/:id',
      name: 'Detail',
      components: { default: Detail }
    },
    {
      path: 'tka/detail/:id',
      name: 'DetailTka',
      components: { default: DetailTka }
    },
    {
      path: 'disabilitas/detail/:id',
      name: 'DetailDisabilitas',
      components: { default: DetailDisabilitas }
    },
    {
      path: 'draft',
      name: 'Draft Sunting',
      components: { default: DraftSunting }
    },
    {
      path: 'tentang',
      name: 'About',
      components: { default: Tentang }
    },
    {
      path: 'add-info-jabatan',
      name: 'Beri Susulan Informasi Jabatan',
      components: { default: addInfoJabatan }
    }
  ]
}

const pdfDetail = {
  path: '/pdf-detail',
  name: 'pdf-detail',
  components: { default: PdfSheet }
}

const routes = [
  landingPage,
  home,
  pdfDetail,
  auth
]

export default routes
